import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { useNavigate } from 'react-router-dom';


import Navigation from '../../../../sharedComponents/Navigation';

import { useChatContext } from '../../context.js';
import { useAuthContext } from '../../../../state/providers/auth';
import LanguageSelection from './LanguageSelection';

function ChatMessagesNavigation() {
  const navigate = useNavigate();

  const {
    createChatSession,
    showChatHistoryWindow,
    setShowChatHistoryWindow,
    setHideAuxiliaryPane,
    auxContentToDisplay,
    auxPaneIsVisible,
    setShowAuditTrail,
  } = useChatContext();

  const { isAdmin } = useAuthContext();

  return (
    <Navigation
      title="Bobolink AI"
      leftSectionComponent={ () => {
        return (
          <Stack direction="row" spacing={1} justifyContent="flex-start">
            {
              !showChatHistoryWindow && !auxPaneIsVisible
                ? (
                  <>
                    <IconButton
                      color="primary"
                      aria-label="show chat history menu"
                      onClick={ () => setShowChatHistoryWindow(true) }
                    >
                      <MenuOpenIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="new chat session"
                      onClick={ () => navigate('/') }
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </>
                )
                : null
            }
            { auxPaneIsVisible
              ? null
              : (
                <IconButton
                  color="primary"
                  aria-label="new chat session"
                  disabled={ !auxContentToDisplay }
                  onClick={ () => setHideAuxiliaryPane(hidden => !hidden) }
                >
                  <MenuBookIcon />
                </IconButton>
              )
            }
            <LanguageSelection />
          </Stack>
        );
      } }
      rightSectionComponent={ () => {
        return (
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            { isAdmin
              ? (
                <IconButton
                  color="primary"
                  aria-label="show audit trail"
                  onClick={ () => setShowAuditTrail(show => !show) }
                >
                  <BuildCircleIcon />
                </IconButton>
              )
              : null

            }
          </Stack>
        );
      } }
    />
  );
}

export default ChatMessagesNavigation;
