import React from 'react';

import { useAuthContext } from '../../state/providers/auth.js';

import { ChatProvider, useChatContext } from './context';

import AuxiliaryPane from './AuxiliaryPane';
import ChatHistoryWindow from './ChatHistoryWindow';
import ChatMessagesWindow from './ChatMessagesWindow';
import AuditChatSession from './AuditChatSession';
// import MicroLessonWindow from './MicroLessonWindow';

import './index.scss';

function ChatWindow() {
  const { selectedOrganizationId } = useAuthContext();

  if (!selectedOrganizationId) return null;

  return (
    <ChatProvider>
      <ChatWindowInner />
    </ChatProvider>
  );
}

function ChatWindowInner() {
  const {
    showChatHistoryWindow,
    showAuditTrail,
  } = useChatContext();

  return (
    <div
      className="chat-window-container"
    >
      {
        showChatHistoryWindow
          ? (
            <ChatHistoryWindow />
          )
          : null
      }
      <AuxiliaryPane />
      <ChatMessagesWindow />

      {
        showAuditTrail
          ? (
            <AuditChatSession />
          )
          : null
      }
    </div>
  );
}

export default ChatWindow;