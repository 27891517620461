import GetStartedExamples from './GetStartedExamples';
import ChatMessages from './ChatMessages';
import Navigation from './Navigation';
import ChatInput from './ChatInput';

import notFoundPng from './not-found.png';

import { useChatContext } from '../context.js';

import './index.scss';

function ChatMessagesWindow() {
  const {
    initializing,
    messages,
    creatingChatSession,
    noSuchChatSession,
  } = useChatContext();

  console.log({
    initializing,
    messages,
    creatingChatSession,
  });

  if (initializing) {
    return null;
  }

  if (noSuchChatSession) {
    return (
      <div className="no-such-chat-session-window">
        <h1>No Such Chat Session</h1>
        <div className="not-found-image-container">
          <img
            alt="not found"
            src={ notFoundPng }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="chat-messages-window">
      <Navigation />
      <div className="chat-window-body">
        {
          creatingChatSession
            ? (<GetStartedExamples />)
            : messages && messages.length
              ? (<ChatMessages />)
              : null
        }
        <ChatInput />
      </div>
    </div>
  );
}

export default ChatMessagesWindow;
