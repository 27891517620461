import { useChatContext } from '../../context.js';

import './index.scss';

const examples = [
  // {
  //   exampleInput: 'I want to learn how to use R'
  // },
  {
    exampleInput: 'I want to learn machine learning.'
  },
  {
    exampleInput: 'I want to learn python.'
  },
  {
    exampleInput: 'I want to learn statistical modeling.'
  }
];

function GetStartedExamples() {
  const { submitUserMessage } = useChatContext();

  return(
    <div className="get-started-examples-container">
      <h1>What do you want to learn today?</h1>
      <p>There are many ways to use Bobolink AI. Try one of these options to get started:</p>

      <div className="get-started-examples">
        { examples.map(example => {
          const { exampleInput } = example;
          return (
            <div
              key={ exampleInput }
              className="get-started-example"
              onClick={ () => submitUserMessage(exampleInput) }
            >
              <h2>"{ exampleInput }"</h2>
            </div>
          );
        }) }
      </div>
    </div>
  );
}

export default GetStartedExamples;
