import * as React from 'react';

// import IconButton from '@mui/material/IconButton';
// import Stack from '@mui/material/Stack';
// import ClearIcon from '@mui/icons-material/Clear';
// import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import AddCircleIcon from '@mui/icons-material/AddCircle';

import Navigation from '../../../../sharedComponents/Navigation';

// import { useChatContext } from '../../context.js';

function AuditChatSession() {
  // const {
  //   setHideAuxiliaryPane,
  //   auxPaneIsVisible,
  //   showChatHistoryWindow,
  //   setShowChatHistoryWindow,
  //   createChatSession,
  // } = useChatContext();

  return (
    <Navigation
      title="Audit Chat Session"
      leftSectionComponent={ () => {
        return null;
      } }
      rightSectionComponent={ () => {
        return null;
      } } 
    />
  );
}

export default AuditChatSession;
