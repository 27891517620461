import React from 'react';
import { Button, Modal as MuiModal } from '@mui/material';

import './index.scss';

export default function Modal(props) {
  const {
    className,
    open,
    hideX,
    onClose,
    onCancel,
    onFinished,
    successDisabled,
    cancelTitle,
    successTitle,
    hideCancel,
    hideSuccess,
    children,
    overflowVisible,
    hideFooter,
    width = '600px',
    height = 'unset',
    contentOverflowVisible = false,
    contentOverflowScroll = false,
    customButtons = []
 } = props;
  return (
    <MuiModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={ open }
      onClose={ onClose }
    >
      <div className={ `main-modal-container ${ className ? className : '' }` }>
        <div
          className={ `main-modal-main ${ overflowVisible ? 'overflow-visible' : '' }` }
          style={{ maxWidth: width, height }}
        >
          <div
            className="main-modal-nav-bar"
            style={{ maxWidth: width }}
          >
            { !hideX && (
              <div className="main-modal-nav-bar-inner">
                <div
                  className="main-modal-nav-bar-exit"
                  onClick={ onClose }
                >
                  X
                </div>
              </div>
            ) }
          </div>
          <div className={ `main-modal-content ${ contentOverflowVisible ? 'overflow-visible' : '' } ${ contentOverflowScroll ? 'overflow-scroll' : '' }` }>
            { children }
          </div>

          {
            hideFooter
              ? null
              : (
                <div className="main-modal-buttons">
                  <div className="main-modal-buttons-inner">
                    {
                      customButtons.map(CustomButton => {
                        return (<CustomButton />);
                      })
                    }
                    { hideSuccess
                      ? null
                      : (
                          <Button
                            variant="contained"
                            onClick={ onFinished }
                            disabled={ successDisabled }
                            color="primary"
                          >
                            { successTitle || 'Confirm' }
                          </Button>
                        )
                    }
                    { hideCancel 
                      ? null
                      : (
                          <Button
                            variant="contained"
                            onClick={ onCancel }
                            color="grey"
                          >
                            { cancelTitle || 'Cancel' }
                          </Button>
                        )
                    }
                  </div>
                </div>
                )
          }
        </div>
      </div>
    </MuiModal>
  )
};