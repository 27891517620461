import { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { CopyBlock, dracula } from 'react-code-blocks';
import { SERVER_URL } from '../../../../config';

import './index.scss';
import { useAuthContext } from '../../../../state/providers/auth.js';
import SubmitFeedback from './SubmitFeedback';

function ChatMessage({ message }) {
  const { token } = useAuthContext();

  const {
    id,
    replyType,
    messageText,
    images,
    feedback
    // microLesson,
  } = message;

  const MessageImages = useCallback(() => {
    if (!images?.length || replyType !== 'user') return null;
    return (
      <>
        { images.map(imageId => {
          return (
            <div
              key={ imageId }
              className={ `chat-message-container user` }
            >
              { /* todo: don't include token as get parameter, instead send as part of headers (permits image caching) */ }
              <img
                src={ `${SERVER_URL}/api/file/${imageId}?token=${token}` }
                width={40}
                height={40}
                alt="user img"
              />
            </div>
          )
        }) }
      </>
    );
  }, [images, replyType]);


  return (
    <>
      <MessageImages />
      <div
        key={ id }
        className={ `chat-message-container ${ replyType === 'user' ? 'user' : 'system' }` }
      >
       
        <div className="chat-message-inner">
          <div className="chat-message-text">
            
            { replyType === 'user'
              ? messageText
              : (
                <>
                  <ReactMarkdown
                    children={messageText}
                    components={{
                      code: (config) => {
                        const { node, inline, className, children, ...props } = config;
                        const language = className?.replace('language-', '');
                        // console.log('react markdown config', config);

                        const isInline = inline !== undefined ? inline : !/\n/.test(children);

                        if (isInline) {
                          // Return inline code
                          // console.log('inline node', node);
                          return <code className={ `${className ? className : ''} inline` } {...props}>{children}</code>;
                        } else {
                          // Return code block
                          // console.log('block node', node);
                          return (
                            <CopyBlock
                              text={String(children).replace(/\n$/, '')}
                              language={language || 'text'}  // Ensure default language is set
                              wrapLines={false}
                              theme={dracula}
                              // {...props}
                            />
                          );
                        }
                      },
                    }}
                  />
                  <SubmitFeedback message={ message } />
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatMessage;
