import _ from 'lodash';

import { useChatContext } from '../../context';

import './index.scss';

function SessionPlan({ chatSession }) {
  const { getAndSetOverrideAuxContent } = useChatContext();

  const { sessionPlan, currentSessionPlanIndex } = chatSession;

  const onPreviousStepClick = (stepIndex) => {
    console.log('onPreviousStepClick', stepIndex);
    getAndSetOverrideAuxContent(stepIndex);
  };

  if (!currentSessionPlanIndex || !sessionPlan) return null;
  console.log('currentSessionPlanIndex', currentSessionPlanIndex);

  return (
    <div className="session-plan-container">
      { Object.entries(sessionPlan).map(([chapterIndex, { name, children }]) => {

        const levelOfDepth = 0;
        const isTheActiveLevelOfDepth = _.isNil(currentSessionPlanIndex[levelOfDepth + 1]);
        
        const isSectionActive = +currentSessionPlanIndex[levelOfDepth] === +chapterIndex;
        const isSectionPreviouslyActive = +chapterIndex < +currentSessionPlanIndex[levelOfDepth];

        const isActive = isTheActiveLevelOfDepth && isSectionActive;

        const isPreviouslyActive = +chapterIndex <= +currentSessionPlanIndex[levelOfDepth];
        const isClickable = isActive || isPreviouslyActive;

        const classes = ['session-plan-chapter-text'];
        if (isActive) classes.push('active');
        classes.push(isClickable ? 'clickable' : 'not-clickable');

        const stepIndex = [+chapterIndex];

        return (
          <div className="session-plan-chapter">
            <div
              className={ classes.join(' ') }
              onClick={
                isClickable
                  ? () => onPreviousStepClick(stepIndex)
                  : () => {}
              }
            >
              <div className="bullet">•</div>
              <div className="main-text">{ name }</div>
            </div>
            { children
              ? (
                <ChapterList
                  levelOfDepth={ levelOfDepth + 1 }
                  steps={ children }
                  isParentSectionActive={ isSectionActive }
                  isParentSectionPreviouslyActive={ isSectionPreviouslyActive }
                  currentSessionPlanIndex={ currentSessionPlanIndex }
                  parentStepIndex={ stepIndex }
                />
              )
              : null
            }
          </div>
        );
      }) }
    </div>
  );
}

function ChapterList({
  steps,
  levelOfDepth,
  isParentSectionActive,
  isParentSectionPreviouslyActive,
  currentSessionPlanIndex,
  parentStepIndex
}) {
  const { getAndSetOverrideAuxContent } = useChatContext();

  const onPreviousStepClick = (stepIndex) => {
    console.log('onPreviousStepClick', stepIndex);
    getAndSetOverrideAuxContent(stepIndex);
  };

  return (
    <div className="session-plan-chapter-subchapters">
      { Object.entries(steps).map(([
        subChapterIndex,
        { name: subchapterName, children: subchapterSteps },
      ]) => { 

        const stepIndex = [...parentStepIndex, +subChapterIndex];

        const isTheActiveLevelOfDepth = _.isNil(currentSessionPlanIndex[levelOfDepth + 1]);

        const isSectionActive =
          isParentSectionActive
          && (+currentSessionPlanIndex[levelOfDepth] === +subChapterIndex);

        const isSectionPreviouslyActive =
          isParentSectionPreviouslyActive
          || (isParentSectionActive && +subChapterIndex < +currentSessionPlanIndex[levelOfDepth])

        const isActive = isSectionActive && isTheActiveLevelOfDepth;

        const isPreviouslyActive =
          isParentSectionActive && (+subChapterIndex <= +currentSessionPlanIndex[levelOfDepth])
          || isParentSectionPreviouslyActive;

        // console.log('subchapter', {
        //   subchapterName,
        //   isActive,
        //   isSectionActive,
        //   isPreviouslyActive,
        //   isParentSectionActive,
        //   isSectionPreviouslyActive,
        //   isParentSectionPreviouslyActive,
        //   currentSessionPlanIndex,
        //   subChapterIndex,
        //   currentSessionPlanIndex,
        // });

        
        const isClickable = isActive || isPreviouslyActive;

        const classes = ['session-plan-subchapter-text'];
        classes.push(isClickable ? 'clickable' : 'not-clickable');
        if (isActive) {
          classes.push('active');
        }
        return (
          <>
            <div
              className={ classes.join(' ') }
              onClick={
                isClickable
                  ? () => onPreviousStepClick(stepIndex)
                  : () => {}
              }
            >
              <div className="bullet">•</div>
              <div className="main-text">{ subchapterName }</div>
            </div>
            { subchapterSteps
              ? (
                <ChapterList
                  levelOfDepth={ levelOfDepth + 1 }
                  steps={ subchapterSteps }
                  isParentSectionActive={ isSectionActive }
                  isParentSectionPreviouslyActive={ isSectionPreviouslyActive }
                  currentSessionPlanIndex={ currentSessionPlanIndex }
                  parentStepIndex={ stepIndex }
                />
              )
              : null
            }
          </>
        );
      }) }
    </div>
  );
}

export default SessionPlan;
