import CircularProgress from '@mui/material/CircularProgress';

import './index.css';

export default function Loading({ centered = true }) {
  return (
    <div className="loading-component">
      <CircularProgress />
    </div>
  )
};
