import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import FormTextInput from '../../../../sharedComponents/FormTextInput';
import ErrorBox from '../../../../sharedComponents/ErrorBox';

import { useChatContext } from '../../context.js';
import { CHAT_WINDOW_MAX_WIDTH_PX } from '../../../../config';

import './index.scss';

function ChatInput() {
  const {
    messages,
    errorMessage,
    messageBody,
    inputDisabled,
    submissionDisabled,
    messageInputFieldRef,
    setMessageBody,
    submitUserMessage,
    setErrorMessage,
    lastUserMessage,
    handleMessageBodyChange,
    invalidSessionState,
    setMessageFiles,
  } = useChatContext();

  const onInputFieldEnter = async (e) => {
    e.preventDefault();
    if (!e.shiftKey) {
      if (submissionDisabled) return;
      await submitUserMessage(messageBody);
    }
    else {
      // user did a shift+enter => interpret it as a carriage return
      setMessageBody(body => body+'\n');
    }
  };
  
  function onRetry() {
    submitUserMessage(lastUserMessage);
    setErrorMessage(null);
  }

  const handleAttachmentClick=(e)=>{
    const newFile = e.target.files[0]
    if (newFile) {
      setMessageFiles(prev => ([...prev, newFile]));
      // reset the file input after adding it
      e.target.value = '';
    }
  }

  // this is a temporary measure, until we are tracking conversation state on the backend
  // via something like a redis cache. in that case we would check if
  // a) the system is typing (or the user is typing)
  // b) if the system is processing a user message
  // the point is that we don't rush to show the user that the conversation is in an invalid state
  const [readyToShowInvalid, setReadyToShowInvalid] = useState(false);

  useEffect(() => {
    setReadyToShowInvalid(false);
    const timer = setTimeout(() => {
      setReadyToShowInvalid(true);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer when the component is unmounted
  }, [messages]);

  if (invalidSessionState && readyToShowInvalid) {
    return (
      <div
        className="chat-input-container"
        style={{ maxWidth: `${CHAT_WINDOW_MAX_WIDTH_PX}px` }}
      >
        <ErrorBox error={ 
          `An unsupported session state has been reached. Please start a new chat session to continue`
         }/>
      </div>
    )
  }

  return (
    <div
      className="chat-input-container"
      style={{ maxWidth: `${CHAT_WINDOW_MAX_WIDTH_PX}px` }}
    > 
      {
        !errorMessage?.length
        ? (
          <>
            <QuickReplies />
            <div className="input-with-attachment">
              <div className='input-with-attachment-btn'>
              <label htmlFor='attachment'>📎</label>
              <input
                type={"file"}
                id="attachment"
                hidden
                className="attachment-button" 
                onChange={ handleAttachmentClick }
                aria-label="Attach file"
              />
            </div> 
            <FormTextInput
              inputRef={ messageInputFieldRef }
              value={ messageBody }
              onChange={ handleMessageBodyChange }
              multiline
              minRows="2"
              maxRows="20"
              fullWidth
              className="compose-message-body"
              onEnter={ onInputFieldEnter }
              disabled={ inputDisabled }
              placeholder="Type your message here ..."
              // label="Message Body"
            />
            </div>
          </>
        )
        : null
      }
      <SelectedImagePreview />
      <ErrorBox
        error={ errorMessage }
        onRetry={ onRetry }
      />
    </div>
  );
}

function SelectedImagePreview() {
  const { messageFiles, setMessageFiles } = useChatContext();
  const removeImage = image => {
    setMessageFiles(prev => {
      return prev.filter(f => f !== image)
    })
  }

  if (!messageFiles?.length) return null;

  return (
    <>
      {
        messageFiles.map((selectedImage)=>(
          <div className="selected-image-preview">
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected Attachment"
              className="attachment-preview"
            />
            <button
              onClick={() => removeImage(selectedImage)}
              className="remove-image-button"
            >
              ✖️
            </button>
          </div>
        ))}
    </>
  );
}

function QuickReplies() {
  const {
    inputDisabled,
    quickReplies,
    submitUserMessage,
  } = useChatContext();
  
  const onQuickReplyButtonPress=async(value)=>{
    await submitUserMessage(value);
  }

  if (!quickReplies?.length) {
    return null;
  }

  return (
    <Box 
      display="flex" 
      justifyContent="space-around" 
      flexWrap="wrap" 
      gap={1} 
      marginBottom={2}
      className="quick-replies-container"
    >
      {quickReplies.map(({ replyText }, index) => (
        <Button
          key={index}
          variant="contained"
          className="quick-reply-button"
          style={{
            height: '30px', 
            flexGrow: 0.45,
          }}
          onClick={() => {
            onQuickReplyButtonPress(replyText);
          }}
          disabled={inputDisabled}
        >
          {replyText}
        </Button>
      ))}
    </Box>
  );
}

export default ChatInput;