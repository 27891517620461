import React from 'react';
import './index.scss';

function NotFoundPage() {
  return (
    <div className="lost-page">
      <div className="lost-page-content">
      <h1>Page Not Found</h1>
      </div>
    </div>
  );
};


export default NotFoundPage;