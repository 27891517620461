import { useEffect, useState } from 'react';
import { api } from '@bobolinkai/common';
import moment from 'moment';
import { CopyBlock, dracula } from 'react-code-blocks';

import Loading from '../../../sharedComponents/Loading';
import Modal from '../../../sharedComponents/Modal';

import Navigation from './Navigation';
import { useChatContext } from "../context";

import './index.scss';

function roundToDecimalPlaces(num, places) {
  const str = (`${Math.round(`${num}e+${places}`)}e-${places}`);
  return Number(Number.parseFloat(str).toFixed(places));
}

function formatPrice(price) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(price);
};


const pricePer1MilInputTokensDollars = {
  'gpt-4o': 2.50, // $2.50 / 1M input tokens
  'gpt-4o-2024-08-06':  2.50,
  'gpt-4o-2024-05-13': 5.00,
  'gpt-4o-mini': 0.15,
  'gpt-4o-mini-2024-07-18': 0.15,
};

const pricePer1MilOutputTokensDollars = {
  'gpt-4o': 10.00, // $2.50 / 1M input tokens
  'gpt-4o-2024-08-06': 10.00,
  'gpt-4o-2024-05-13': 15.00,
  'gpt-4o-mini': 0.60,
  'gpt-4o-mini-2024-07-18': 0.60,
};

const getQueryCost = (model, inputTokens, outputTokens) => {
  const inputPrice = pricePer1MilInputTokensDollars[model];
  const inputCost = inputPrice * (+inputTokens / 1000000);

  const outputPrice = pricePer1MilOutputTokensDollars[model];
  const outputCost = outputPrice * (+outputTokens / 1000000);

  console.log('query cost inputs', {
    inputPrice,
    inputCost,
    outputPrice,
    outputCost,
  });

  return roundToDecimalPlaces(inputCost + outputCost, 4);
};

const getTotalConversationCost = chatMessages => {
  let totalCost = 0;
  chatMessages.forEach(message => {
    if (message.replyType === 'system') {
      const { metadata } = message;
      const { queries } = metadata;

      queries.forEach(query => {
        const { response } = query;
        const inputTokens = response?.usage?.prompt_tokens;
        const outputTokens = response?.usage?.completion_tokens;
        const model = response.model;
        const queryCost = getQueryCost(model, inputTokens, outputTokens);
        totalCost += queryCost;
      });
    }
  });
  
  return roundToDecimalPlaces(totalCost, 2);
}

function AuditChatSession() {
  const [loadedChatSession, setLoadedChatSession] = useState(null);
  const { chatSession, messages } = useChatContext();
  const [auditTrail, setAuditTrail] = useState(null);
  const [selectedQuery, setSelectedQuery] = useState();

  useEffect(() => {
    console.log('considering getting audit trail', {
      chatSession,
      loadedChatSession,
    });

    const newChatSessionLoaded = loadedChatSession?.id !== chatSession?.id;
    const newMessagesForChatSession =
      auditTrail?.chatMessages?.length && messages?.length
      && auditTrail.chatMessages.length !== messages.length;

    if (chatSession?.id && (newChatSessionLoaded || newMessagesForChatSession)) {
      const chatSessionToBeLoaded = chatSession;
      console.log('getting audit trail for chat session', chatSessionToBeLoaded);
      api.chat.session.getAuditTrail({ sessionId: chatSession.id })
        .then(auditTrail => {
          console.log(`got audit trail for chat session ${chatSessionToBeLoaded.id}`, auditTrail);
          setAuditTrail(auditTrail);
          setLoadedChatSession(chatSessionToBeLoaded);
        })
        .catch(err => {
          // todo: set error
          console.error(err);
        });
    }
  }, [chatSession, messages]);

  console.log('audit trail', auditTrail);

  return (
    <div className="audit-chat-session">
      <Navigation />
      <div className="audit-chat-session-body">
        <div className="audit-chat-session-body-contents">
          { !auditTrail
            ? (<Loading />)
            : (
              <>
                <h3>Total Cost: { formatPrice(getTotalConversationCost(auditTrail.chatMessages)) }</h3>
                <div className="chat-messages">
                  { auditTrail
                      .chatMessages
                        .filter(m => m.replyType === 'system')
                        .sort((mA, mB) => moment(mA.createdAt).isBefore(mB.createdAt) ? -1 : 1)
                        .map(chatMessage => {

                          const { metadata } = chatMessage;
                          const { queries } = metadata;

                          return (
                            <div key={ chatMessage.id } className="chat-message">
                              <div>ID: { chatMessage.id }</div>
                              <div>Queries: {queries.length}</div>
                              <div className="chat-message-queries">
                                { queries.map((llmQuery, index) => {
                                  const { api, startTime, endTime, query, response } = llmQuery;
                                  const inputTokens = response?.usage?.prompt_tokens;
                                  const outputTokens = response?.usage?.completion_tokens;

                                  const model = response.model;

                                  const queryCost = getQueryCost(model, inputTokens, outputTokens);

                                  console.log('query cost', queryCost);
                                  return (
                                    <div
                                      key={ index }
                                      className="chat-message-query"
                                      onClick={ () => setSelectedQuery(llmQuery) }
                                    >
                                      <div>startTime: { startTime }</div>
                                      <div>endTime: { endTime }</div>
                                      <div>input tokens: { inputTokens }</div>
                                      <div>output tokens: { outputTokens }</div>
                                      <div>cost: ${ queryCost }</div>
                                    </div>
                                  );
                                }) }
                              </div>
                            </div>
                          );
                  }) }
                </div>
              </>
            )
          }
        </div>
      </div>

      <QueryDetailsModal
        query={ selectedQuery }
        setSelectedQuery={ setSelectedQuery }
      />
    </div>
  );
}

function QueryDetailsModal({ query, setSelectedQuery }) {

  if (!query) return null;

  const { query: request, response } = query;

  const onClose = () => {
    setSelectedQuery(null);
  };

  return (
    <Modal
      open={ !!query }
      onClose={ onClose }
      onCancel={ onClose }
      hideFooter
      contentOverflowScroll
    >
      <div className="query-modal-contents">
        <div className="query-request">
          <h3>Request</h3>

          { request.messages.map(({ role, content }) => {
            return (
              <div>
                <h4>Role: { role }</h4>
                <p style={{ whiteSpace: 'pre-wrap' }}>{ content }</p>
              </div>
            );
          }) }

          {/* <CopyBlock
            text={JSON.stringify(request, 0, 2)}
            language="JSON"
            showLineNumbers={false}
            theme={dracula}
            codeBlock={ true } // Indicates whether to render the CopyBlock as an inline Code component or a CodeBlock component
          /> */}
        </div>

        <div className="query-response">
          <h3>Response</h3>

          { response.choices.map(({ message }) => {
            const { role, content } = message;
            return (
              <div>
                <h4>Role: { role }</h4>
                <p style={{ whiteSpace: 'pre-wrap' }}>{ content }</p>
              </div>
            );
          }) }

        </div>
      </div>
    </Modal>
  );
}

export default AuditChatSession;
