import React, { useState, useEffect, useMemo } from 'react';
import { Menu, MenuItem, IconButton, Stack, Typography, Tooltip } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import Flag from 'react-world-flags';

import { useChatContext } from '../../../context';
import { defaultLanguageCode } from './config';

function LanguageSelection() {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    messages,
    inputDisabled,
    selectedLanguage,
    setSelectedLanguage,
    chatSession,
    supportedLanguages,
  } = useChatContext();

  const languageSelectionDisabled = useMemo(() => {
    return inputDisabled || messages.length;
  }, [inputDisabled, messages]);

  useEffect(() => {
    if (supportedLanguages) {
      if (!selectedLanguage) {
        const code = chatSession?.language || defaultLanguageCode;
        setSelectedLanguage(supportedLanguages.find(l => l.code === code));
      }
      else if (
        selectedLanguage
        && chatSession?.language
        && chatSession?.language !== selectedLanguage
      ) {
        const code = chatSession?.language;
        setSelectedLanguage(supportedLanguages.find(l => l.code === code));
      }
    }
  }, [chatSession, supportedLanguages, selectedLanguage, setSelectedLanguage]);

  const handleMenuOpen = (event) => {
    if (!languageSelectionDisabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    handleMenuClose(); // Close the menu after selection
  };

  return (
    <>
      <Tooltip
        title={
          languageSelectionDisabled
          ? 'Language selection is disabled after a chat session has been started'
          : 'Change language'
        }
      >
        <span> {/* Wrapping in span to ensure tooltip works when button is disabled */}
          <IconButton
            color="primary"
            onClick={handleMenuOpen}
            disableRipple
            disabled={!supportedLanguages || languageSelectionDisabled}
          >
            {
              selectedLanguage
              ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Flag
                    code={selectedLanguage.code.split('-')[1]} // Extracting country code for the flag
                    alt={selectedLanguage.name}
                    height="20"
                  />
                </Stack>
              )
              : <LanguageIcon /> // Use the LanguageIcon as fallback if no language is selected
            }
          </IconButton>
        </span>
      </Tooltip>

      {
        supportedLanguages && !languageSelectionDisabled && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {supportedLanguages.map((language) => {
              const { code, name } = language;

              return (
                <MenuItem
                  key={code}
                  selected={code === selectedLanguage?.code}
                  onClick={() => handleLanguageChange(language)}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Flag
                      code={code.split('-')[1]} // Extracting country code for the flag
                      alt={name}
                      height="20"
                    />
                    <Typography variant="body1">
                      {name}
                    </Typography>
                  </Stack>
                </MenuItem>
              );
            })}
          </Menu>
        )
      }
    </>
  );
}

export default LanguageSelection;
