import React, { useState } from 'react';
import { Button } from '@mui/material';

import { useAuthContext } from '../../state/providers/auth';
import FormTextInput from '../../sharedComponents/FormTextInput';
import ErrorBox from '../../sharedComponents/ErrorBox';

import './index.scss';

function Login() {
  const [submitting, setSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);

  const { login } = useAuthContext();

  const allFieldsValid = loginInfo.email && loginInfo.password;

  const submissionDisabled = submitting || !allFieldsValid;

  const onEmailChange = (e) => {
    setError(null);
    setLoginInfo({
      ...loginInfo,
      email: e.target.value
    });
  };

  const onPasswordChange = (e) => {
    setError(null);
    setLoginInfo({
      ...loginInfo,
      password: e.target.value
    });
  };

  const submitLoginForm = async (e) => {
    e.preventDefault();
    if (submissionDisabled) return;

    setSubmitting(true)
    if (allFieldsValid) {
      try {
        await login(loginInfo);
      }
      catch (err) {
        setError('Unknown error. Please check your email/password and try again.');
      }
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h2>Login</h2>

      <FormTextInput
        label={ 'Email' }
        type="email"
        onChange={ onEmailChange }
        onEnter={ submitLoginForm }
        className="login-email-input"
        error={ error }
      />
      <FormTextInput
        obfuscate
        label={ 'Password' }
        onChange={ onPasswordChange }
        onEnter={ submitLoginForm }
        error={ error }
      />

      {/* <p>Forgot your password? <a onClick={ onForgotPassword }>Click here</a> to set up a new one.</p> */}

      <ErrorBox error={ error } />

      <div className="login-section-footer">
        <Button
          disabled={ submissionDisabled }
          onClick={ submitLoginForm }
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Login
        </Button>
      </div>
    </div>
  );
}

export default Login;
