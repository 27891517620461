import React, { useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import Loading from '../../../sharedComponents/Loading';
import SessionPlan from './SessionPlan';
import Navigation from './Navigation';

import { CHAT_HISTORY_WINDOW_WIDTH_PX } from '../../../config';

import './index.scss';
import { useChatContext } from '../context.js';

function ChatHistoryWindow() {
  return (
    <div
      className="chat-history-window"
      style={{
        maxWidth: `${CHAT_HISTORY_WINDOW_WIDTH_PX}px`,
      }}
    >
      <Navigation />
      <ChatSessions />
    </div>
  );
}

function ChatSessions() {
  const {
    chatSession: activeChatSession,
    chatSessions,
    fetchingChatSessions: fetching,
    errorFetchingChatSessions: error,
    // setActiveChatSession,
    creatingChatSession,
    noSuchChatSession,
  } = useChatContext();

  const navigate = useNavigate();

  console.log('chatSession to be sorted', chatSessions);
  const sortedChatSessions = useMemo(() => {
    if (!chatSessions) return [];
    return chatSessions.sort((a, b) => {
      if (!a.lastMessageAt || !b.lastMessageAt) {
        if (moment(a.createdAt).isBefore(b.createdAt)) {
          return 1;
        }
        return -1;
      }
      if (moment(a.lastMessageAt).isBefore(b.lastMessageAt)) {
        return 1;
      }
      return -1;
    })
  }, [chatSessions]);

  if ((fetching || !sortedChatSessions.length || (!activeChatSession && !noSuchChatSession)) && !creatingChatSession) {
    return null;
  }

  if (error) {
    return (
      <div>Error loading chat sessions</div>
    );
  }

  return (
    <div className="chat-sessions">

      { creatingChatSession 
        ? (
          <ChatSession
            key={ 'new-session' }
            isActive={ true }
            title={ 'New Session' }
          />
        )
        : null
      }

      { sortedChatSessions.map(chatSession => {
        const { id, title, sessionPlan } = chatSession;
        const isActiveChatSession = activeChatSession?.id === id;

        return (
          <ChatSession
            key={ id }
            isActive={ isActiveChatSession }
            title={ title }
            sessionPlan={ sessionPlan }
            chatSession={ chatSession }
            onClick={ () => {
              if (!isActiveChatSession) {
                navigate(`/session/${id}`);
              }
            } }
          />
        );
      })}
    </div>
  );
}

function ChatSession({
  title,
  sessionPlan,
  isActive,
  onClick = () => {},
  chatSession
}) {
  return (
    <div
      className={ `chat-session-container ${ isActive ? 'active' : '' }` }
      onClick={ onClick }
    >
      <p className="chat-session-name">{ title || 'Untitled' }</p>

      { isActive && sessionPlan
        ? (<SessionPlan chatSession={ chatSession } />)
        : null
      }
    </div>
  );
}

export default ChatHistoryWindow;
