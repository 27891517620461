import Navigation from './Navigation';

import FormattedAuxiliaryContent from '../../../sharedComponents/FormattedAuxiliaryContent';
import { useChatContext } from '../context';

import './index.scss';

function AuxiliaryPane() {
  const {
    hideAuxiliaryPane,
    auxContentToDisplay
  } = useChatContext();

  if (!auxContentToDisplay || hideAuxiliaryPane) {
    return null;
  }

  const { sections, title } = auxContentToDisplay.contents;

  return (
    <div className="auxiliary-pane">
      <Navigation title={ title } />
      <div className="auxiliary-pane-body">
        <div className="auxiliary-pane-body-contents">
          <FormattedAuxiliaryContent sections={ sections } />
        </div>
      </div>
    </div>
  );
}

export default AuxiliaryPane;
