import moment from 'moment';

import Loading from '../../../../sharedComponents/Loading';
import TypingIndicator from '../../../../sharedComponents/TypingIndicator';
import ChatMessage from '../ChatMessage';

import { useChatContext } from '../../context.js';

import { CHAT_WINDOW_MAX_WIDTH_PX } from '../../../../config';

import './index.scss';

function ChatMessages() {
  const {
    initializing,

    chatSession,
    messages,
    systemTypingForSessions,
    errorMessage,
    messagesContainerRef,
  } = useChatContext();

  return (
    <div
      className="chat-messages-body"
      style={{ maxWidth: `${CHAT_WINDOW_MAX_WIDTH_PX}px` }}
      ref={ messagesContainerRef }
    >
      { initializing
        ? null
        : (
            <div className="chat-messages-body-inner">
              { messages
                .sort((a, b) => {
                  if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
                    return 1;
                  }
                  return -1;
                })
                .map(message => {
                  return (
                    <ChatMessage
                      key={ message.id }
                      message={ message }
                    />
                  );
                })
              }
              {
                systemTypingForSessions?.[chatSession?.id] && !errorMessage?.length 
                  ? (
                    <div className="chat-message-container system">
                      <div className="chat-message-inner">
                        <TypingIndicator />
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
        )
      }
    </div>
  );
}

export default ChatMessages;
