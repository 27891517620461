import { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Login from '../Login';
import ChatWindow from '../ChatWindow';
import Loading from '../../sharedComponents/Loading';
// import UserSettings from '../UserSettings';
import NotFoundPage from '../NotFoundPage';

import { useAuthContext } from '../../state/providers/auth';

import './index.scss';

const views = {
  login: {
    component: Login,
    notLoggedInOnly: true,
  },
  chat: {
    component: ChatWindow,
    authOnly: true,
  },
};

function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated, authResolving } = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated && !authResolving && !location.pathname.startsWith('/login')) {
      navigate('/login');
    }
    else if (isAuthenticated && location.pathname.startsWith('/login')) {
      navigate('/');
    }
  }, [isAuthenticated, authResolving, location]);

  if (authResolving) {
    return null;
  }

  return (
    <div className="main-view-component-container">
      <Routes>
        <Route
          path="/"
          element={
            <ChatWindow />
          }
        />
        <Route
          path="/session/:sessionId"
          element={
            <ChatWindow />
          }
        />
        <Route
          path="/login"
          element={
            <Login />
          }
        />
        <Route
          path="/*"
          element={ <NotFoundPage /> }
        />
      </Routes>
    </div>
  );
}

export default Main;
