import { CopyBlock, dracula } from 'react-code-blocks';
import { marked } from "marked";

function FormattedAuxiliaryContent({ sections }) {
  return (
    <div>
      { sections.map(section => {
        const { type, content, sectionBlockContent = [] } = section;

        if (type === 'section block') {
          return (
            <div>
              { sectionBlockContent.map(sectionBlockContentSection => {
                const { blockType, codeType, blockContent} = sectionBlockContentSection;

                if (blockType === 'text') {
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked.parse(blockContent)
                      }}
                    />
                  );
                }
                else if (blockType === 'code') {
                  return (
                    <>
                      <CopyBlock
                        text={blockContent}
                        language={codeType}
                        showLineNumbers={false}
                        theme={dracula}
                        codeBlock={ true } // Indicates whether to render the CopyBlock as an inline Code component or a CodeBlock component
                      />
                    </>
                  );
                }
                else {
                  return (
                    <p>ERROR: SHOULD NEVER SEE THIS</p>
                  );
                }
              }) }
            </div>
          );
        }
        else if (type === 'subtitle') {
          return(
            <h2>{ content }</h2>
          );
        }
        else {
          return null;
        }
      }) }
    </div>
  );
}

export default FormattedAuxiliaryContent;
