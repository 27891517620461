import React from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';

import Navigation from '../../../../sharedComponents/Navigation';

import { useChatContext } from '../../context.js';

function ChatHistoryNavigation() {
  const navigate = useNavigate();
  const {
    // createChatSession,
    setShowChatHistoryWindow,
    noSuchChatSession,
  } = useChatContext();

  return (
    <Navigation
      title="Chat Sessions"
      leftSectionComponent={ () => {
        return (
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <IconButton
              color="primary"
              aria-label="show auxiliary content menu"
              onClick={ () => setShowChatHistoryWindow(false) }
              disabled={ noSuchChatSession }
            >
              <MenuOpenIcon />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="new chat session"
              onClick={ () => navigate('/') }
            >
              <AddCircleIcon />
            </IconButton>
          </Stack>
        );
      } }
      rightSectionComponent={ () => {
        return (
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {/* <IconButton
              color="primary"
              aria-label="show chat history menu"
            >
              <ClearIcon />
            </IconButton> */}
          </Stack>
        );
      } }
    />
  );
}

export default ChatHistoryNavigation;
