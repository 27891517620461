import React from 'react';

import './index.scss';  // Make sure to import the CSS

const TypingIndicator = () => {
  return (
    <div className="typing-indicator">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
};

export default TypingIndicator;
