import React, { useState } from 'react';

import { Modal, Box, Button, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';

import { api } from '@bobolinkai/common';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import { useChatContext } from '../../../context';

const FeedbackModal = ({
  open,
  onClose,
  onSubmit,
  isLikeClicked,
}) => {
  
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [feedbackText, setFeedbackText] = useState('');

  const handleOptionToggle = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        // Remove the option if it's already selected
        return prevSelectedOptions.filter((selectedOption) => selectedOption !== option);
      }
      else {
        // Add the option if it's not selected
        return [...prevSelectedOptions, option];
      }
    });
  };

  const handleSubmit = () => {
      onSubmit({ selectedOptions, feedbackText });
      setSelectedOptions([]);
      setFeedbackText("");
      onClose();
  };

  const dislike = [
    'Don\'t like the style',
    'Not factually correct',
    'Didn\'t fully follow instructions',
    'Refused when it shouldn\'t have',
    'Being lazy',
    'Unsafe or problematic',
    'Something else...'
  ];
  
  const like = [
    'I like the style',
    'Factually correct',
    'Fully followed instructions',
    'Obliged odd request',
    'Being extra accommodating',
    'Safe and careful',
    'Something else...'
  ]

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  return (
    <Modal
      open={ open }
      onClose={
        () => {
          setSelectedOptions([]);
          setFeedbackText("");
          onClose()
        }
      }
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          borderRadius: 2,
          p: 4,
          boxShadow: 24,
        }}
      >
        <Box sx={headerStyle}>
        <Typography
          variant="h6"
          component="h2" mb={2}
        >
          Your Feedback
        </Typography>
        <IconButton
          onClick={
            () => {
              setSelectedOptions([]);
              setFeedbackText("");
              onClose()
            }
          }
          aria-label="close">
          <CloseIcon />
        </IconButton>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
          { isLikeClicked
            ? like.map((option, index) => (
                <Button
                  key={index}
                  variant={selectedOptions.includes(option) ? 'contained' : 'outlined'}
                  onClick={() => handleOptionToggle(option)}
                  sx={{
                    height: 50, // Adjust button height
                    whiteSpace: 'nowrap', // Keep text in a single line
                    minWidth: 120, // Adjust width to fit text on a single line
                    borderColor: 'rgba(128, 0, 0, 0.5)',
                    color: selectedOptions.includes(option) ? '#fff' : 'rgba(128, 0, 0, 0.8)',
                    bgcolor: selectedOptions.includes(option) ? 'rgba(128, 0, 0, 1)' : 'transparent',
                  }}
                >
                  {option}
                </Button>
              ))
            : dislike.map((option, index) => (
                <Button
                  key={index}
                  variant={selectedOptions.includes(option) ? 'contained' : 'outlined'}
                  onClick={() => handleOptionToggle(option)}
                  sx={{
                    height: 50, // Adjust button height
                    whiteSpace: 'nowrap', // Keep text in a single line
                    minWidth: 120, // Adjust width to fit text on a single line
                    borderColor: 'rgba(128, 0, 0, 0.5)',
                    color: selectedOptions.includes(option) ? '#fff' : 'rgba(128, 0, 0, 0.8)',
                    bgcolor: selectedOptions.includes(option) ? 'rgba(128, 0, 0, 1)' : 'transparent',
                  }}
                >
                  {option}
                </Button>
              ))}
        </Box>
        
        <TextField
          fullWidth
          multiline
          rows={3}
          placeholder="Write your feedback here..."
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          sx={{ mb: 2 }}
        />
        
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={selectedOptions.length === 0 && !feedbackText}
        >
          Submit Feedback
        </Button>
      </Box>
    </Modal>
  );
};

function SubmitFeedback({ message }) {
  const { messages, setMessages } = useChatContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const [isDislikeClicked, setIsDislikeClicked] = useState(false);

  const onFeedbackClicked = goodOrBad => {
    if (goodOrBad === 'good') {
      setIsLikeClicked(true);
    }
    else if (goodOrBad === 'bad') {
      setIsDislikeClicked(true);
    }
    setIsModalOpen(true);
  }

  const handleCloseModal = () =>{
     setIsDislikeClicked(false);
     setIsLikeClicked(false);
     setIsModalOpen(false);
  }

  const handleFeedbackSubmit = async ({ selectedOptions, feedbackText }) => {
    try {
      const obj = {
        goodOrBad: isLikeClicked ? 'good' : 'bad',
        goodOrBadReason: selectedOptions,
        moreInformation: feedbackText, 
        submittedAt: new Date().toISOString(),
      }

      const data  = await api.chat.session.addFeedback({
        messageId: message.id,
        feedback: obj,
      });

      if (data) {
        console.log('data from feedback', data);
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          const messageIndex = prevMessages.findIndex(m => m.id === message.id);
          updatedMessages[messageIndex].feedback = data?.updatedMessage?.feedback;
          return updatedMessages;
        });
      }
    }
    catch (err) {
      console.error("Error submitting feedback",err);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '53px',
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
    >
      <Stack direction="row" spacing={1}>
        <IconButton
          aria-label="Good"
          disabled={ message.feedback?.goodOrBad }
          onClick={ () => onFeedbackClicked('good') }
        >
          { message.feedback?.goodOrBad === "good" 
            ? (
              <ThumbUpAltIcon />
            )
            : (
              <ThumbUpOffAltIcon />
            )
          }
        </IconButton>
        <IconButton
          aria-label="Bad"
          disabled={ message.feedback?.goodOrBad }
          onClick={ () => onFeedbackClicked('bad') }
        >
          { message.feedback?.goodOrBad === "bad" 
            ? (
              <ThumbDownAltIcon />
            )
            : (
              <ThumbDownOffAltIcon />
            )
          }
        </IconButton>
      </Stack>

      <FeedbackModal
        isLikeClicked={ isLikeClicked }
        open={ isModalOpen }
        onClose={ handleCloseModal }
        onSubmit={ handleFeedbackSubmit }
      />
    </div>
  );
}

export default SubmitFeedback;
