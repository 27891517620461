import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './index.css';

export default function FormTextInput({
  inputRef,
  label,
  obfuscate,
  disableSubmitOnEnter = true,
  onEnter,
  className,
  ...props
}) {
  const textFieldProperties = { ...props };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (disableSubmitOnEnter) {
        e.preventDefault();
      }
      if (onEnter) {
        onEnter(e);
      }
    }
  };

  if (obfuscate) {
    textFieldProperties.type = 'password';
  }

  if (inputRef) {
    textFieldProperties.inputRef = inputRef;
  }

  return (
    <Box component="form" noValidate autoComplete="off" className={className}>
      <TextField
        id="outlined-basic"
        label={ label }
        variant="outlined"
        type="text"
        onKeyDown={ onKeyDown }
        className="form-text-input-field"
        { ...textFieldProperties }
      />
    </Box>
  );
}