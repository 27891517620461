import { useState, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import * as Sentry from "@sentry/react";
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './state/providers/auth';

import Main from './components/Main';

import { themeConfig, NODE_ENV, SENTRY_DSN, SERVER_URL } from './config';

Sentry.init({
  environment: NODE_ENV,
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', SERVER_URL],
  // Session Replay
  replaysSessionSampleRate: NODE_ENV === 'production' ? 1.0 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    // Check if the current environment is 'development'
    if (['development'].includes(NODE_ENV)) {
      console.log("skipping sending to sentry because env is not staging or production");
      return null;
    }
    return event;
  },
});

const ErrorView = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        maxWidth: '800px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <h2>Uh oh!</h2>
        <p>It looks like an error occurred somewhere. Our team is taking a look. Please reload the page, clear your cookies, reset your cache, and try again. We appreciate your patience.</p>
      </div>
    </div>
  );
}

const theme = createTheme(themeConfig);

function App() {
  const [token, updateToken] = useState(null);

  const resetToken = useCallback(newToken => {
    if (!newToken) {
      localStorage.removeItem('token');
      updateToken(null);
    }
    else {
      localStorage.setItem('token', newToken);
      updateToken(newToken);
    }
  }, [updateToken]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorView}>
      <ThemeProvider theme={ theme }>
        <BrowserRouter>
          <AuthProvider resetToken={ resetToken } token={ token }>
            <div className="App">
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Main />
              </Box>
            </div>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
